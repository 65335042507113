import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

// import confirmQuestionPackUrl from "../images/confirm-question.png"

window.Swal = Swal;

// Behavior after click to confirm button
const confirmed = (event, result) => {
  // If they confirmed the action`
  if (result.isConfirmed) {
    const target = getTargetFromEvent(event);
    target.removeAttribute('data-confirm-swal');
    target.click();
  }
};

const getTargetFromEvent = (event) => {
  return event.target.tagName === "A" || event.target.tagName === "INPUT" || event.target.tagName === "BUTTON" ? event.target : event.target.closest('a');
}

// Display the confirmation dialog
const showConfirmationDialog = (event) => {
  const target = getTargetFromEvent(event);
  const message = target.getAttribute('data-confirm-swal');
  const text = target.getAttribute('data-text');

  Swal.fire({
    title: message || 'Are you sure?',
    text: text || '',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    keydownListenerCapture: true,
    confirmButtonAriaLabel: 'Confirm delete',
    cancelButtonText: 'Cancel',
    cancelButtonAriaLabel: 'Cancel delete',
    customClass: {
      title: 'color-fanword-black font-fanword-regular',
      confirmButton: 'btn-sm px-4 py-2 text-decoration-none bg-fanword-lightgreen color-fanword-darkgreen font-fanword-extra-bold fanword-no-focus',
      cancelButton: 'btn-sm px-4 py-2 text-decoration-none bg-fanword-background color-fanword-text font-fanword-extra-bold fanword-no-focus'
    }
  }).then(result => {
    if (result.isConfirmed) {
      confirmed(event, result)
    }
  });

};

function handleConfirm(event) {
  Rails.stopEverything(event);

  event.preventDefault();
  event.stopPropagation();

  showConfirmationDialog(event);
}

Rails.delegate(document, '[data-confirm-swal]', 'click', handleConfirm)
